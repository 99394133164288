body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.diy-upload:hover .diy-upload-item {
  display: flex !important;
}

.diy-upload-item:hover {
  display: flex !important;
}

.design_model .ant-modal {
  width: 90vw !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  width: 4px;
  background-color: #999;
}
.smallBadge{
  .ant-badge-status-text{
    font-size: 12px !important;
    color: #666 !important;
  }
}
