.wraper {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 0 0 11px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 32px;
}
.wraper:hover {
  border-color: #00a85f;
}