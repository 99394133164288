.companyQuery {
  display: flex;
  flex-direction: column;
}

.companyTableDesc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
