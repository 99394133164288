.userQuery {
  display: flex;
  flex-direction: row;
}

.btns {
  display: flex;
  flex-direction: row;
}

.userTab {
  margin-left: 14px;
}

.userTableDesc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarUpload {
  display: flex;
  flex-direction: row;
}

.avatar {
  margin-left: 8px;
}

.computer_tag {
  display: flex;
  flex-direction: row;
  align-items: center;
}
