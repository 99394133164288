.accountQuery {
  display: flex;
  flex-direction: column;
}

.accountTableDesc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.avatarUpload {
  display: flex;
  flex-direction: row;
}

.avatar {
  margin-left: 8px;
}
