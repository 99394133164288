.account {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
}

.job {
  min-width: 600px;
}

.operaButs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.operaBut {
  max-width: 100px !important;
  display: block !important;
}

.tdInput {
  width: auto !important;
}

.td {
  padding: 8px !important;
}

.powerSelect {
  display: flex;
  flex-direction: row;
}

.powerItem {
  max-height: 500px;
  overflow-y: auto;
  margin-left: 24px;
  width: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user {
  min-width: 400px;
  margin-left: 24px !important;
}
